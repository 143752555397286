/* * {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

.logo-img {
  width: 200px;
  height: 200px;
  min-width: 200px;
  max-width: 200px;
  max-height: 200px;
  object-fit: contain;
}

#mainimage {
  max-width: 320px;
  max-height: 300px;
}

#container {
  height: 100vh;
}

.hide {
  visibility: hidden;
}

.redeem-swag-btn {
  border-radius: 100px;
  background-color: #edb941;
  font-weight: 700;
  border-style: none;
  width: 180px;
}

.outer-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

#mainimage {
  max-width: 320px;
  max-height: 300px;
}

#container {
  height: 100vh;
}

.hide {
  visibility: hidden;
}

.redeem-swag-btn {
  border-radius: 100px;
  background-color: #edb941;
  font-weight: 700;
  border-style: none;
  width: 180px;
}

.outer-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.heading-5 {
  font-size: 14px;
}

.heading {
  color: #f5f5f5;
  font-size: 46px;
  font-weight: 600;
}
.header{
  background:#1E1665;
}
.below-heading-content {
  color: #dbeefe;
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.loading-indicator-admin {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999999999999999;
  background: url("../assets//images/Gift-box-loading-animation.gif") 50% 50%
    no-repeat white;
}

.mobileViewButton {
  display: none;
  margin-top: 40px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  color: white;
}

.mobileFooter {
  display: none;
}

@media screen and (min-width: 250px) and (max-width: 400px) {
  .footer {
    display: none;
  }

  .mobileFooter {
    display: block;
    padding-top: 50px;
    left: 0;
    bottom: 0px;
    width: 100%;
    color: white;
  }

  .mobileViewButton {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .desktopViewButton {
    display: none;
  }

  #container {
    height: 159vh !important;
  }

  .mobileScreenPadding {
    margin-top: 250px !important;
  }
}

@media screen and (min-width: 401px) and (max-width: 720px) {
  .footer {
    display: none;
  }

  .mobileFooter {
    display: block;
    padding-top: 50px;
    left: 0;
    bottom: 0px;
    width: 100%;
    color: white;
  }

  .mobileViewButton {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .desktopViewButton {
    display: none;
  }

  #container {
    height: 138vh !important;
  }

  .mobileScreenPadding {
    margin-top: 120px !important;
  }
} */



* {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

.logo-img {
  margin-top: 10px;
  width: 200px;
  height: 200px;
  min-width: 200px;
  max-width: 200px;
  max-height: 200px;
  object-fit: contain;
}

#mainimage {
  max-width: 320px;
  max-height: 300px;
}

#container {
  height: 100vh;
}

.hide {
  visibility: hidden;
}

.redeem-swag-btn {
  border-radius: 100px;
  background-color: #edb941;
  font-weight: 700;
  border-style: none;
  width: 180px;
}

.outer-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

#mainimage {
  min-height: 500px;
  min-width: 500px;
  object-fit: contain;
}

#container {
  height: 100vh;
}

.hide {
  visibility: hidden;
}

.redeem-swag-btn {
  border-radius: 100px;
  background-color: #edb941;
  font-weight: 700;
  border-style: none;
  width: 180px;
}

.outer-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.heading-5 {
  font-size: 14px;
}

.heading {
  color: #f5f5f5;
  font-size: 46px;
  font-weight: 600;
}

.below-heading-content {
  color: #dbeefe;
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.loading-indicator-admin {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999999999999999;
  /* background: url("../assets//images/Gift-box-loading-animation.gif") 50% 50%
    no-repeat white; */
}

.mobileViewButton {
  display: none;
  margin-top: 40px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  color: white;
}

.mobileFooter {
  display: none;
}

@media screen and (min-width: 250px) and (max-width: 400px) {
  .footer {
    display: none;
  }

  .mobileFooter {
    display: block;
    padding-top: 50px;
    left: 0;
    bottom: 0px;
    width: 100%;
    color: white;
  }

  .mobileViewButton {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .desktopViewButton {
    display: none;
  }

  #container {
    height: 159vh !important;
  }

  .mobileScreenPadding {
    margin-top: 300px !important;
  }
}

@media screen and (min-width: 401px) and (max-width: 720px) {

  .footer {
    display: none;
  }

  .mobileFooter {
    display: block;
    padding-top: 50px;
    left: 0;
    bottom: 0px;
    width: 100%;
    color: white;
  }

  .mobileViewButton {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .desktopViewButton {
    display: none;
  }

  #container {
    height: 138vh !important;
  }

  .mobileScreenPadding {
    margin-top: 120px !important;
  }
  .margin{
    margin: auto;
  }
}

@media only screen and (min-width: 768px) {
  .margin{
    margin: auto;
  }

}
