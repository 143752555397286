
.sub-footer{
    background:#7567F8;
  }
  .footer-link{
    height:30vh;
    background:#1E1665;
  }
  .border-bottom{
    border-bottom:1px solid #ccc;
    margin-top:12px;
    margin:23px;
  }

