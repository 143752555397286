@import "~react-image-gallery/styles/css/image-gallery.css";

* {
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

body {
    background-color: #fff !important;
    /* background-image: url("../assets/images/gift-of-choice-images/confetti5.png"); */
    box-sizing: border-box;
}

body.enable-scrolling {
    overflow-y: scroll !important;

}

.loading-indicator-admin {
    position: fixed;
    left: 0px;
    margin: 0px !important;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999999999999999;
    background: url('../assets//images/Gift-box-loading-animation.gif') 50% 50% no-repeat white;
}

.screen {
    padding: 3rem 0rem;
    position: relative;
}

.choose-gift-text {
    font-weight: 600;
    font-size: 2rem;
    height: fit-content;
    color: #012754;
}

@media (max-width: 1050px) {

    .modal-card {
        width: 800px !important;
        flex-direction: column !important;
    }

    .image-gallery-content {
        margin-top: 3rem;
        height: 50% !important;
    }


    .modal-img-container {
        width: 100% !important;
        margin-bottom: 1.5rem !important;
        height: 100% !important;
    }

    .product-content {
        width: 100% !important;
        height: 50% !important;
    }
}

@media (max-width: 880px) {
    .modal-card {
        width: 600px !important;
    }

    .image-gallery {
        height: fit-content !important;
    }

    .image-gallery-content {
        height: fit-content !important;
    }

    .modal-img-container {
        height: 450px !important;
    }

    .image-gallery-slide-wrapper {
        right: 20% !important;
    }

}

@media (max-width: 692px) {
    .modal-card {
        width: 400px !important;
    }

    .button-container {
        flex-direction: column !important;
        bottom: 6% !important;
        max-height: 80px;
        min-height: 80px;
    }

    .list-box {
        min-height: 40px !important;
        max-height: 40px !important;
    }

    .button-container button {
        width: 95% !important;
    }

    .choose-gift-btn {
        margin: 0.5rem 0rem .7rem 0rem !important;
    }

    .image-gallery-slide-wrapper {
        right: 15% !important;
    }

    .modal-img-container {
        max-height: 300px !important;
    }
}

@media (max-width: 522px) {
    .modal-card {
        width: 300px !important;
    }

    .image-gallery-thumbnails .image-gallery-thumbnails-container::-webkit-scrollbar {
        height: 7px !important;
        background-color: gainsboro !important;
    }

    .image-gallery-thumbnails .image-gallery-thumbnails-container::-webkit-scrollbar-thumb {
        height: 5px !important;
        margin: 0rem 1rem !important;
        background-color: #7567F8 !important;
        border-radius: 5px !important;
    }

    .product-description {
        max-height: 250px !important;
    }

    .image-gallery-slide-wrapper {
        right: 5% !important;
    }

    .modal-img-container {
        max-height: 320px !important;
    }

}




@media (max-width: 850px) {

    .choose-gift-text {
        padding: 0rem 2rem;
    }
}

@media (max-width: 692px) {

    .choose-gift-text {
        font-size: 1.7rem;
    }

    .single-img-container {
        width: 210px !important;
        height: 210px !important;
    }

    .single-img-container img {
        max-width: 120px !important;
        max-height: 120px !important;
    }

    .image-gallery-thumbnails {
        max-height: 177px !important;
        margin-top: 1.8rem !important;
    }
}

.single-img-container:hover {
    box-shadow: 0px 8px 8px 2px #00000040;
}


.customProductDescModal {
    max-width: fit-content !important;
    min-height: 550px !important;
    padding: 0px !important;
    border: #B3ABF8 1px solid !important;
    border-radius: 10px !important;
}

.modal-card {
    margin: 0rem;
    width: 1000px;
    overflow: hidden;
    display: flex;
    margin: auto auto;
    align-items: center;
}

.modal-img-container {
    width: 50%;
}

.modal-single-img-container {
    border: #7567F8 2px solid;
    border-radius: 15px !important;
    width: 80% !important;
    height: 100% !important;
    margin: auto auto !important;
}

.modal-single-img-container img {
    width: 80% !important;
    height: 80% !important;
}

.carousel button {
    padding: 10px 20px;
    background-color: lightblue;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.product-content {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    min-height: 550px;
    padding: 1.5rem;
    background-color: #f5fcff;
    position: relative;
}

.product-heading {
    font-size: 1.7rem;
    font-weight: 600;
    color: rgba(51, 51, 51, 1);
}

.description-heading {
    font-size: 1.4rem;
    font-weight: 600;
    color: rgba(51, 51, 51, 1);
    margin-top: 1rem;
}

.description-text {
    font-size: 1rem;
    color: rgba(51, 51, 51, 1);
    line-height: 2rem;
    margin-top: 1rem;
}

.features-heading {
    font-size: 1.4rem;
    font-weight: 600;
    color: rgba(51, 51, 51, 1);
}

.features-list {
    font-size: 1rem;
    color: rgba(51, 51, 51, 1);
}

.button-container {
    position: absolute;
    bottom: 5%;
    width: 90%;
}

.btn:hover {
    box-shadow: 0px 4px 15px px #00000040 !important;
}

.back-btn {
    width: 30%;
    margin-right: 1rem;
    font-size: 1.2rem !important;
    font-weight: 600 !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    color: #012754 !important;
    border-radius: 20px !important;
    box-shadow: 0px 4px 4px 0px #00000040;
    border: #012754 1px solid !important;
}

.choose-gift-btn {
    width: 40%;
    font-size: 1.2rem !important;
    font-weight: 600 !important;
    color: #ffffff !important;
    border-radius: 20px !important;
    box-shadow: 0px 4px 4px 0px #00000040;
    background: linear-gradient(100.45deg, #23BBEB 1.74%, #2D74EC 53.29%, #B689FF 96.19%);
}

.image-gallery {
    padding: 0px !important;
}

.image-gallery-icon {
    color: #012754 !important;
    transition: all .3s ease-out;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 1;
    filter: drop-shadow(0 2px 2px #1a1a1a)
}

@media(hover: hover)and (pointer: fine) {
    .image-gallery-icon:hover {
        color: #7567F8 !important;
    }

    .image-gallery-icon:hover .image-gallery-svg {
        transform: scale(1.1)
    }
}

.image-gallery-play-button {
    bottom: 0;
    padding: 20px;
}

.image-gallery-play-button .image-gallery-svg {
    height: 40px !important;
    width: 40px !important;
}

@media(max-width: 768px) {

    .image-gallery-play-button {
        padding: 15px
    }

    .image-gallery-play-button .image-gallery-svg {
        height: 24px;
        width: 24px
    }
}

@media(max-width: 480px) {

    .image-gallery-play-button {
        padding: 10px
    }

    .image-gallery-play-button .image-gallery-svg {
        height: 16px;
        width: 16px
    }
}

.image-gallery-play-button {
    right: 0
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    padding: 50px 0px;
    top: 50%;
    transform: translateY(-50%)
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 90px;
    width: 30px;
}

@media(max-width: 768px) {

    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 72px;
        width: 36px
    }
}

@media(max-width: 480px) {

    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 48px;
        width: 24px
    }
}

.image-gallery-left-nav {
    left: 0;
}

.image-gallery-right-nav {
    right: 0;
}

.image-gallery {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
    padding-top: 30px;
    position: relative
}



.image-gallery-content {
    position: relative;
    height: 500px !important;
    top: 0
}

.image-gallery-slide-wrapper {
    position: relative;
    margin-bottom: 2rem !important;
}

.image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl
}

.image-gallery-slides {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-align: center;
}

.image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.image-gallery-slide.center {
    position: relative
}

@media (max-width:880px) {

    .customProductDescModal {
        margin: 2rem 0rem 3rem 0rem !important;
    }

    .image-gallery-slide .image-gallery-image {
        width: 250px !important;
        height: 250px !important;
    }

    .image-gallery-thumbnail {
        height: 90px !important;
        width: 90px !important;
    }

    .image-gallery-thumbnail .image-gallery-thumbnail-image {
        max-height: 80px !important;
        max-width: 80px !important;
    }

    .features-list ul {
        padding-left: 1rem !important;
    }
}

.image-gallery-slide .image-gallery-image {
    width: 60% !important;
    height: 320px;
    padding-top: 2rem;
    object-fit: contain
}

.image-gallery-thumbnails-wrapper {
    position: relative
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
    touch-action: pan-y
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical {
    touch-action: pan-x
}

.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
    direction: rtl
}

.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
    display: inline-block;
    vertical-align: top;
    width: 100px
}

@media(max-width: 768px) {

    .image-gallery-thumbnails-wrapper.left,
    .image-gallery-thumbnails-wrapper.right {
        width: 81px
    }
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
    height: 150px;
    width: 150px;
    left: 0;
    padding: 0;
    position: absolute;
    top: 0
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
    display: block;
    margin-right: 0;
    padding: 0
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail {
    margin-left: 0;
    margin-top: 2px
}

.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
    margin: 0 5px
}

@media(max-width: 768px) {

    .image-gallery-thumbnails-wrapper.left,
    .image-gallery-thumbnails-wrapper.right {
        margin: 0 3px
    }
}

.image-gallery-thumbnails {
    overflow: hidden;
    padding: 5px 0;
}

@media(max-width: 768px) {
    .image-gallery-thumbnails {
        padding: 3px 0
    }
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    overflow-x: scroll;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container::-webkit-scrollbar {
    background-color: transparent;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.image-gallery-thumbnail {
    display: inline-block;
    border: 1.5px solid #B3ABF8;
    border-radius: 10px !important;
    transition: border .3s ease-in;
    width: 80px;
    height: 80px;
    background: none !important;
    padding: 0;
    margin-right: 1rem;
}

/* review */
@media(max-width: 768px) {
    .image-gallery-thumbnail {
        border: 1px solid #B3ABF8;
    }
}

.image-gallery-thumbnail+.image-gallery-thumbnail {
    margin-left: 2px
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    display: block;
    position: relative
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    max-height: 80px;
    max-width: 80px;
    line-height: 0
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
    outline: none !important;
    border: 2.5px solid #B3ABF8 !important;
}

.image-gallery-thumbnail.active {
    border: 2.5px solid #B3ABF8 !important;
}

.image-gallery-thumbnail-label {
    box-sizing: border-box;
    color: #fff;
    font-size: 1em;
    left: 0;
    line-height: 1em;
    padding: 5%;
    position: absolute;
    top: 50%;
    text-shadow: 0 2px 2px #1a1a1a;
    transform: translateY(-50%);
    white-space: normal;
    width: 100%
}

@media(max-width: 768px) {
    .image-gallery-thumbnail-label {
        font-size: .8em;
        line-height: .8em
    }
}

.image-gallery-index {
    background: rgba(0, 0, 0, .4);
    color: #fff;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4
}

@media(max-width: 768px) {
    .image-gallery-index {
        font-size: .8em;
        padding: 5px 10px
    }
}

.btn:hover {
    box-shadow: 0px 4px 15px px #00000040;
    border: #7567F8 1px solid;
}

.red {
    border: 10px solid red;
}

.modal-card {
    background-color: rgba(247, 247, 247, 1);
}

.product-content {
    background-color: rgba(255, 255, 255, 1);
}

.image-gallery-content {
    background-color: rgba(247, 247, 247, 1);
}

.product-heading {
    margin-top: 12px;
}

.image-gallery-slide-wrapper {
    max-width: 350px;
    right: 30px;
    position: absolute;

}

.image-gallery-thumbnails-wrapper {
    margin: 20px;
}

.image-gallery-image {
    min-width: 300px;
    min-height: 300px;
    max-width: 300px;
    max-height: 300px;
}

.image-gallery-thumbnail {
    padding: 20px;
}

.image-gallery-thumbnail-image {
    max-height: 60px !important;
    max-width: 60px !important;
}

.image-gallery-thumbnails-container {
    margin-left: 20px;
}

.image-gallery-thumbnails-container button {
    margin-top: 10px !important;
}

.image-gallery-thumbnails {
    height: 300px !important;
}

.image-gallery-content {
    height: 400px !important;
}

@media screen and (min-width: 880px) and (max-width: 1050px) {
    .image-gallery-image {
        min-width: 300px !important;
        min-height: 300px !important;
        max-width: 300px !important;
        max-height: 300px !important;
    }

    .image-gallery-slide-wrapper {
        max-width: 350px;
        right: 30%;
        position: absolute
    }

}

@media screen and (min-width: 522px) and (max-width: 692px) {
    .image-gallery-image {
        min-width: 220px !important;
        min-height: 220px !important;
        max-width: 220px !important;
        max-height: 220px !important;
    }

    .image-gallery-thumbnail {
        max-height: 80px !important;
        max-width: 80px !important;
    }

    .image-gallery-thumbnail-image {
        padding: 10px;
        width: 60px;
    }

    
    .product-description {
        max-height: 300px !important;
    }

}

@media screen and (max-width: 522px) {
    .image-gallery-image {
        min-width: 200px !important;
        min-height: 200px !important;
        max-width: 200px !important;
        max-height: 200px !important;
    }

    .image-gallery-thumbnail {
        max-height: 60px !important;
        max-width: 60px !important;
    }

    .image-gallery-thumbnail-image {
        padding: 10px;
        width: 80%;
    }

    .image-gallery-slide-wrapper {
        max-width: 350px;
        right: 0%;
        position: absolute
    }

    .image-gallery-content {
        height: 300px !important;
    }

    .modal-img-container {
        height: 300px !important;
    }

    .image-gallery-thumbnails-container {
        width: 70px;
        overflow-x: hidden !important;
    }

}

.product-description {
    scrollbar-width: thin !important;
    scrollbar-color: rgb(8, 18, 36) !important;
}

.product-description::-webkit-scrollbar {
    width: 4px !important;
}

.product-description::-webkit-scrollbar-track {
    background: rgba(247, 247, 247, 1) !important;
}

.product-description::-webkit-scrollbar-thumb {
    background-color: rgba(45, 116, 236, 1) !important;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 30px;
    justify-content: center;
    margin: 0 auto;
    max-width: 1400px;
}

.single-product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 8px 8px 2px #00000040;
    border-radius: 1rem;
    background: #fff;
}

.image-container {
    height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.product-image {
    max-width: 240px;
    max-height: 240px;
    min-width: 170px;
    min-height: 235px;
    object-fit: cover;
    cursor: pointer;
}

.product-details {
    flex: 1;
    padding: 10px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    color: white;
    background-image: linear-gradient(100.45deg, #23BBEB 1.74%, #2D74EC 53.29%, #B689FF 96.19%);
}

.product-name {
    font-size: 16px;
    line-height: 1.2;
    padding-top: 6px;
    padding-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.sender-choice {
    color: rgba(251, 228, 34, 1);
    font-size: 16px;
}

@keyframes zoomIn {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.5);
    }
}

@media screen and (min-width: 768px) and (max-width: 990px) {
    .grid-container {
        grid-template-columns: repeat(2, minmax(250px, 1fr));
    }
}

@media screen and (min-width: 990px) and (max-width: 1024px) {
    .grid-container {
        grid-template-columns: repeat(3, minmax(250px, 1fr));
    }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
    .grid-container {
        grid-template-columns: repeat(3, minmax(250px, 1fr));
    }
}

@media screen and (min-width: 1280px) and (max-width: 1500px)  {
    .grid-container {
        grid-template-columns: repeat(3, minmax(250px, 1fr)) !important;
    }
}
