* {
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

.loading-indicator-admin {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999999999999999;
    background: url('../assets//images/Gift-box-loading-animation.gif') 50% 50% no-repeat white;
}

body {
    background-color: #f5fcff;
    box-sizing: border-box;
}

.page {
    height: 100%;
    background: #f5fcff;
    box-sizing: border-box;
}

.leftProductPicture {
    width: 50%;
    background-color: rgb(255,255,255);
    padding: 3rem 0rem;
}

.lightHeading {
    font-size: 1.5rem;
    color: #668A9A;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.productName {
    font-size: 2rem;
    color: #012754;
    font-weight: 600;
    padding: 0rem 3rem;
    margin-bottom: 4rem;
}

.rightAddressForm {
    width: 50%;
    padding: 3rem 0rem 1rem 0rem;
    background-color: rgb(247,246,255);
}

.heading-question {
    font-size: 3rem;
    color: #012754;
    font-weight: 600;
}

.guideline {
    font-size: 1.2rem;
}

.addressForm {
    padding: 0rem 2rem;
    margin: 0rem !important;
}

.addressForm label {
    font-size: .9rem;
    font-weight: 550;
    color: #012754;
    margin-bottom: 3px;
}

.addressForm input,
.addressForm select {
    height: 50px;
    font-size: 1.1rem;
    color: #012754;
    border: none;
    border-radius: 8px;
    padding-left: 1rem;
    border: 1px solid rgba(1, 39, 84, 1);
}

.addressForm select option {
    margin: 1rem !important;
}

.react-tel-input{
    border-radius: 8px !important;
}

.react-tel-input .flag-dropdown {
    margin-right: 0px !important;
    width: 40px !important;
    background-color: #fff !important;
    border: 1px solid rgba(1, 39, 84, 1) !important;

}

.react-tel-input .flag-dropdown .selected-flag {
    margin-right: 0px !important;
    width: fit-content !important;
}

.react-tel-input .flag-dropdown .country-list {
    width: 200px !important;
    height: 200px !important;
    
}

.react-tel-input .flag-dropdown .selected-flag .flag .arrow {
    width: 6px;
}

.react-tel-input input.form-control {
    width: 100% !important;
    height: 50px !important;
    border: 1px solid rgba(1, 39, 84, 1) !important;

}

.emailAddress {
    padding: 0 12px;
}

.emailForm {
    padding: 0 15px;
}

.backSubmitButtonContainer {
    /* padding: 0rem 58px 30px 58px; */
    margin-top: 1.5rem;
}

@media (max-width: 900px) {
    .page {
        flex-direction: column;
    }

    .leftProductPicture {
        width: 100%;
    }

    .rightAddressForm {
        width: 100%;
    }
}

@media (max-width: 522px) {

    .leftProductPicture {
        padding: 1rem 0rem 2.5rem 0rem !important;
    }

    .lightHeading {
        font-size: 1.2rem;
    }

    .productName {
        font-size: 1.7rem;
        padding: 0rem 2rem;
        margin-bottom: 2rem;
    }

    .img-container img {
        height: 200px;
    }

    .rightAddressForm {
        border-top: 1px solid darkgrey;
        padding: 1.7rem 1rem 2rem 1rem;
    }

    .heading-question {
        font-size: 1.7rem;
    }

    .guideline {
        margin-top: 1.5rem;
        font-size: 1rem !important;
    }

    .firstLastName {
        flex-direction: column;
    }

    .firstLastName div {
        width: 100%;
        margin-left: 0rem !important;
    }

    .address {
        flex-direction: column;
    }

    .address div {
        width: 100%;
        margin-left: 0rem !important;
    }

    .cityState {
        flex-direction: column;
    }

    .cityState div {
        width: 100%;
        margin-left: 0rem !important;
    }

    .cityState {
        flex-direction: column;
    }

    .cityState div {
        width: 100%;
        margin-left: 0rem !important;
    }

    .zipPhone {
        flex-direction: column;
    }

    .zipPhone div {
        width: 100%;
        margin-left: 0rem !important;
    }

    .countryEmail {
        flex-direction: column;
    }

    .countryEmail div {
        width: 100%;
        margin-left: 0rem !important;
    }

    .emailForm {
        padding: 0rem !important;
    }

    .backSubmitButtonContainer {
        flex-direction: column;
        /* padding: 0px 43px !important; */
    }

    .back-btn-redeem-gift {
        width: 100% !important;
        margin-bottom: 1.5rem !important;
    }

    .submit-btn-redeem-gift {
        width: 100% !important;
        /* margin-bottom: 1.5rem !important;
        margin-left: 0.7rem; */
    }

    .size {
        width: 100%;
        padding-left: 0px !important;
        margin-left: 0rem !important;
        padding-right: 15px !important;
    }

}

.hurrayHeading {
    color: #012754;
    font-weight: 600;
    font-size: 2rem;
}

.done-btn {
    margin-top: 1.5rem;
    width: 40%;
    font-size: 1.2rem !important;
    font-weight: 600 !important;
    color: #ffffff !important;
    border-radius: 1;
    box-shadow: 0px 4px 4px 0px #00000026;
    background-color: #5ABAE3 !important;
}

@media (max-width: 700px) {

    .react-responsive-modal-modal {
        margin-left: .3rem !important;
        margin-right: .3rem !important;
    }

    .react-responsive-modal-modal.customModal {
        padding: .5rem !important;
        max-width: fit-content !important;
        height: fit-content !important;
    }

    .confettiSvg {
        width: 300px !important;
    }

    .confettiSvg img {
        height: 150px !important;
        width: 150px !important;
    }
}

.customModal {
    background: white;
    background-image: url("../assets/images/gift-of-choice-images/Confetti-background.png") !important;
    max-width: 400px !important;
    min-height: fit-content !important;
    border: #B3ABF8 1px solid !important;
    border-radius: 10px !important;
    width: 100% !important;
}

.customModal2{
    background: white;
    max-width: 600px !important;
    min-width: 600px !important;
    max-height: 80vh !important;
    border: #B3ABF8 1px solid !important;
    border-radius: 10px !important;
    width: 100% !important;
    /* min-height: fit-content !important; */
}

.react-responsive-modal-overlay {
    background: rgba(220, 220, 220, 0.247) !important;
    filter: blur(1px) !important;
    backdrop-filter: blur(3px);
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.back-btn-redeem-gift {
     margin-left: 1rem;
    /*width: 22% !important;
    margin-right: 1rem; */
    font-size: 1.2rem !important;
    font-weight: 600 !important;
    color: rgba(1, 39, 84, 1) !important;
    border-radius: 20px !important;
    outline: 0.3px solid rgba(1, 39, 84, 1)  !important;
    border: 1px solid rgba(1, 39, 84, 1)
}

.submit-btn-redeem-gift {
    margin-right: 1rem;
    font-size: 1.2rem !important;
    font-weight: 600 !important;
    color: rgba(255, 255, 255, 1) !important;
    border-radius: 20px !important;
    box-shadow: 0px 4px 4px 0px #00000026;
    background-color: #5ABAE3 !important;
    background-image: linear-gradient(to right, rgba(35, 187, 235, 1), rgba(45, 116, 236, 1), rgba(182, 137, 255, 1));
}

input:focus {
    border: #B3ABF8 1px solid !important;
    outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.btn:hover {
    box-shadow: 0px 4px 15px px #00000040;
    border: #7567F8 1px solid;
}

.red {
    border: 1px solid red;
}

@media (max-width: 522px) {

    .back-btn-redeem-gift {
         margin-left: 0;
    }
    
    .submit-btn-redeem-gift {
         margin-right: 0;
    }
}
@media (max-width: 769px) {

    .react-responsive-modal-modal.customModal2 {
        padding: .5rem !important;
        max-width: 80vw !important;
        min-width: 80vw !important;
        /* height: 80vh !important; */
        /* min-width: 600px !important;
        min-height: fit-content !important; */
    }
}

@media (min-width:901px) and (max-width: 1217px) {
    .small-screen-hide {
        display: none !important;
    }

    .back-btn-redeem-gift {
        max-height: 60px !important;
        max-width: 140px;
   }
   
   .submit-btn-redeem-gift {
        max-height: 40px !important;
   }
}