.pack-details-div {
  gap: 8px;
}

.packProductsModal {
  background: white;
  border: #b3abf8 1px solid !important;
  border-radius: 10px !important;
  width: 100% !important;
  max-height: 85%;
}

.productImg {
  min-height: 100px;
  min-width: 100px;
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
}

.img-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 143px;
  margin: auto;
  border-radius: 50%;
}

.pack-1 {
  background-color: #d5bbdf;
}

.pack-2 {
  background-color: #a3d2ca;
}

.pack-3 {
  background-color: #e7caf2;
}

.pack-4 {
  background-color: #fff6bc;
}

.pack-5 {
  background-color: #c0d6e4;
}

.pack-6 {
  background-color: #f0b7a4;
}

.pack-7 {
  background-color: #d5bbdf;
}

.pack-8 {
  background-color: #a3d2ca;
}

.pack-9 {
  background-color: #e7caf2;
}

.pack-10 {
  background-color: #fff6bc;
}

.pack-11 {
  background-color: #c0d6e4;
}

.pack-12 {
  background-color: #f0b7a4;
}

.desc {
  font-weight: 600;
  font-size: 18px;
}

.product-carousel-container {
  display: flex;
  justify-content: center; 
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  padding: 0 20px;
}

.carousel {
  display: flex;
  justify-content: flex-start;
  transition: transform 0.5s ease;
  overflow: hidden;
  scroll-behavior: smooth;
  width: 100%;
}

.product-thumbnail {
  flex: 0 0 auto;
  margin: 0 10px;
  text-align: center;
  cursor: pointer;
}

.product-imgs-div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5%;
  border: 1px solid black;
  min-width: 250px;
  max-width: 250px;
  margin: 0 22px 0 22px;
}

.image {
  min-width: 200px;
  max-width: 200px;
  min-height: 177px;
  max-height: 177px;
  object-fit: contain;
  padding: 20px;
}

.product-desc {
  font-size: 22px;
  font-weight: 600;
}

.redeem-btn-div {
  display: flex;
  justify-content: end;
}

.product-carousel-prev,
.product-carousel-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 1;
  font-size: 33px;
}

.product-carousel-prev {
  left: 0;
}

.product-carousel-next {
  right: 0;
}

.name-desc {
  padding: 10px;
  max-height: 222px;
  overflow-y: auto;
}

.name-desc::-webkit-scrollbar {
  width: 5px;
}

.name-desc::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 10px;
}

.name-desc::-webkit-scrollbar-track {
  background-color: lightgray;
}

@keyframes customEnterModalAnimation {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes customLeaveModalAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@media screen and (max-width: 370px) {
  .packProductsModal {
    max-width: 85% !important;
    max-height: fit-content !important;
  }

  .redeem-btn-div {
    justify-content: start !important;
  }

  .redeem-btn {
    margin-top: 1rem !important;
  }

  .image {
    min-width: 155px !important;
    max-width: 155px !important;
    min-height: 155px !important;
    max-height: 155px !important;
  }

  .product-imgs-div {
    min-width: 190px !important;
    max-width: 190px !important;
  }

  .product-carousel-cards {
    margin-left: 13px !important;
  }
}

@media screen and (min-width: 371px) and (max-width: 556px) {
  .packProductsModal {
    max-width: 70% !important;
    max-height: fit-content !important;
  }

  .image {
    min-width: 155px !important;
    max-width: 155px !important;
    min-height: 155px !important;
    max-height: 155px !important;
  }

  .product-imgs-div {
    min-width: 180px !important;
    max-width: 180px !important;
  }

  .product-carousel-cards {
    margin-left: 4px !important;
  }
}

@media screen and (min-width: 556px) and (max-width: 777px) {
  .packProductsModal {
    max-width: 50% !important;
    max-height: fit-content !important;
  }

  .redeem-btn-div {
    justify-content: start !important;
  }

  .redeem-btn {
    margin-top: 1rem !important;
  }

  .image {
    min-width: 135px !important;
    max-width: 135px !important;
    min-height: 135px !important;
    max-height: 135px !important;
  }

  .product-imgs-div {
    min-width: 135px !important;
    max-width: 135px !important;
  }

  .product-carousel-cards {
    margin-left: 25px !important;
  }
}
