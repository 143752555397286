.mrgnlft {
    width: 100%;
    margin-left: 60px;
}

.skipbtn {
    width: 100%;
    margin-left: 60px;
}

.loading-indicator-admin {
    position: fixed;
    left: 0px;
    top: 0px;
    margin-top: 64.5px;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: url('../assets/images/Gift-box-loading-animation.gif') 50% 50% no-repeat rgb(249, 249, 249);
}
.bg-card{
    background:#5945D7;
}
.left{
    left:123px;
}
.exp-img{
    width:45px;
}

.order-confirm{
    width:560px;
}
.skip{
    width:190px;
    margin-bottom:12px;
}
.submit{
    width:190px;
    margin-bottom:12px;
}

@media screen and (max-width: 586px) {

    .order-confirm{
        width:260px;
    }
    .left{
        left:0;
    }
    .btn-div{
        margin-left: -30px !important;

    }
    }