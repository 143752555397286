* {
  font-family: "Poppins", sans-serif;
}

.loading-indicator-admin {
  position: fixed;
  left: 0px;
  top: 0px;
  margin-top: 64.5px;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: url("../assets/images/Gift-box-loading-animation.gif") 50% 50%
    no-repeat rgb(249, 249, 249);
}
.product-qty {
  font-size: 30px;
  font-weight: 700;
}

.theme-logo-image {
  width: 9%;
  margin-left: 15px;
  margin-bottom: 11px;
}

.top-margin-two {
  padding: 10px 0px 0px 0px;
}

.gift-exp {
  color: var(--gray-light);
  letter-spacing: 0.8rem;
  font-weight: 300;
  opacity: 0.9;
  padding: 0px 0px 20px 0px;
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-name: fadeIn;
  animation-duration: 1s;
}

.middle-two h4 {
  color: var(--gray-light);
  font-weight: 300;
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-name: fadeIn;
  animation-duration: 1s;
  padding: 20px 0px 40px 0px;
}

.card {
  width: "400px";
  height: "350px";
  box-shadow: "rgba(58, 59, 69, 0.3) 0px 0px 3px";
}

.cardh :hover {
  overflow: hidden;
}

.mockimg {
  min-height: 105px;
  min-width: 105px;
  max-width: 105px;
  max-height: 105px;
  object-fit: contain;
}
.product-card {
  /* background:#D5BBDF; */
  /* width:20vw; */
  height: auto;
}
.header {
  background: #1e1665;
}
.img-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 143px;
  margin: auto;
  border-radius: 50%;
}

.product-imgs-div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5%;
  border: 1px solid black;
  padding: 20px;
}

.product-desc {
  font-size: 22px;
  font-weight: 600;
}

.redeem-btn-div {
  display: flex;
  justify-content: end;
}

.image {
  min-width: 242px;
  max-width: 242px;
  min-height: 177px;
  max-height: 177px;
  object-fit: contain;
}

.pack-1 {
  background-color: #d5bbdf;
}

.pack-2 {
  background-color: #a3d2ca;
}

.pack-3 {
  background-color: #e7caf2;
}

.pack-4 {
  background-color: #fff6bc;
}

.pack-5 {
  background-color: #c0d6e4;
}

.pack-6 {
  background-color: #f0b7a4;
}

.pack-7 {
  background-color: #d5bbdf;
}

.pack-8 {
  background-color: #a3d2ca;
}

.pack-9 {
  background-color: #e7caf2;
}

.pack-10 {
  background-color: #fff6bc;
}

.pack-11 {
  background-color: #c0d6e4;
}

.pack-12 {
  background-color: #f0b7a4;
}

.desc {
  font-weight: 600;
  font-size: 18px;
}

.product-carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease;
  overflow: hidden;
  scroll-behavior: smooth;
}

.product-carousel-cards {
  margin-left: 33px;
}

.product-thumbnail {
  flex: 0 0 auto;
  margin: 0 10px;
  text-align: center;
  cursor: pointer;
}

.product-carousel-prev,
.product-carousel-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 1;
  font-size: 33px;
}

.product-carousel-prev {
  left: 0;
}

.product-carousel-next {
  right: 0;
}

.name-desc {
  padding: 10px;
  max-height: 222px;
  overflow-y: auto;
}

.name-desc::-webkit-scrollbar {
  width: 5px;
}

.name-desc::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 10px;
}

.name-desc::-webkit-scrollbar-track {
  background-color: lightgray;
}

@keyframes customEnterModalAnimation {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes customLeaveModalAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@media screen and (max-width: 666px) {
  .packProductsModal {
    max-width: 85% !important;
    max-height: fit-content !important;
  }

  .redeem-btn-div {
    justify-content: start !important;
  }

  .redeem-btn {
    margin-top: 1rem !important;
  }

  .product-qty {
    font-size: 17px;
  }
  .product-desc {
    font-size: 13px;
  }
  .header {
    padding-bottom: 52px;
  }

  .image {
    min-width: 155px !important;
    max-width: 155px !important;
    min-height: 155px !important;
    max-height: 155px !important;
  }
}

@media screen and (max-width: 767px) {
  .header {
    padding-bottom: 77px;
  }
  .logodiv {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    /* width: 280px; */
    width: 100%;
    max-height: 40px;
  }

  .theme-logo-image {
    max-height: 70px;
    width: 45%;
    justify-content: center;
    display: flex;
    margin: auto;
    object-fit: cover;
  }
}
